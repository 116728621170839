import React from "react"
import { Row, Col, Container, Carousel, CardDeck, Card, Button } from "react-bootstrap"
import "./../../styles/style.scss"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import CarouselImage01 from "./../../images/lutein-slider-lifestyle-mobile.jpg"
import CarouselImage01x2 from "./../../images/lutein-slider-lifestyle-tablet.jpg"
import CarouselImage01x3 from "./../../images/lutein-slider-lifestyle-hd.jpg"

import CarouselImage03 from "./../../images/hepatofelin-slider-lifestyle-mobile.jpg"
import CarouselImage03x2 from "./../../images/hepatofelin-slider-lifestyle-tablet.jpg"
import CarouselImage03x3 from "./../../images/hepatofelin-slider-lifestyle-hd.jpg"

import LuteinComplex from "./../../images/Lutein-Complex-home-@1x.jpg"
import LuteinComplexx2 from "./../../images/Lutein-Complex-home-@2x.jpg"
import LuteinComplexx3 from "./../../images/Lutein-Complex-home-@3x.jpg"

import Hepatofelin from "./../../images/Hepatofelin-home-@1x.jpg"
import Hepatofelinx2 from "./../../images/Hepatofelin-home-@2x.jpg"
import Hepatofelinx3 from "./../../images/Hepatofelin-home-@3x.jpg"

import ProstatizalP from "./../../images/Prostatizal-Plus-home-@1x.jpg"
import ProstatizalPx2 from "./../../images/Prostatizal-Plus-home-@2x.jpg"
import ProstatizalPx3 from "./../../images/Prostatizal-Plus-home-@3x.jpg"

import PsoriazalP from "./../../images/Psoriazal-Plus-home-@1x.jpg"
import PsoriazalPx2 from "./../../images/Psoriazal-Plus-home-@2x.jpg"
import PsoriazalPx3 from "./../../images/Psoriazal-Plus-home-@3x.jpg"

import LaksiproktImg from "./../../images/laksiprokt.jpg"
import LiporeduktImg from "./../../images/liporedukt.jpg"
import MenopauzalImg from "./../../images/menopauzal.jpg"
import MiomalImg from "./../../images/miomal.jpg"
import NevroherbImg from "./../../images/nevroherb.jpg"
import PielonefralImg from "./../../images/pielonefral.jpg"
import ProstatizalImg from "./../../images/prostatizal.jpg"
import PsoriazalImg from "./../../images/psoriazal.jpg"
import VeniprotektImg from "./../../images/veniprotekt.jpg"

import TechImg from "./../../images/licaps-tech.jpg"
import AboutImg from "./../../images/about.jpg"


const IndexPageEn = () => (
  <Layout
    announce={"Lutein Complex Plus available now on BeMore.shop"}
    announceLink={"https://bemore.shop/ena/lutein-complex-envh1002"}
    enSite
    pageInfo={{ pageName: "index-en" }}
  >
    <Seo
      title="Vita Herb"
      description="Vita Herb is a leading manufacturer of dietary supplements in Bulgaria. For more than 15 years now, the company has been working in tight cooperation with Capsugel - France, where most of out products are being produced."
      keywords={[
        `Lutein Complex`,
        `Hepatofelin`,
        `Prostatizal Plus`,
        `Psorizal Plus`,
        `Vita Herb`,
      ]}
    />
    <Container className="px-0">
      <Row className="mb-3">
        <Col>
          <h5 className="section-heading">Featured</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Carousel
            indicators={false}
            fade={true}
            interval={3500}
            className="shadow-1"
          >
            <Carousel.Item>
              <Link to="/en/lutein-complex" className="link-no-style">
                <picture>
                  <source
                    srcSet={CarouselImage01x3}
                    media="(min-width: 992px)"
                  />
                  <source
                    srcSet={CarouselImage01x2}
                    media="(min-width: 767px)"
                  />
                  <source srcSet={CarouselImage01} media="(min-width: 370px)" />
                  <img
                    srcSet={CarouselImage01}
                    alt="Lutein Complex banner"
                    className="d-block w-100 rounded mb-3"
                  />
                </picture>
              </Link>

              <Carousel.Caption className="carousel-caption rounded-bottom p-2">
                <Link to="/en/lutein-complex" className="link-no-style">
                  Lutein Complex aids eyesight
                </Link>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <Link to="/en/hepatofelin" className="link-no-style">
                <picture>
                  <source
                    srcSet={CarouselImage03x3}
                    media="(min-width: 992px)"
                  />
                  <source
                    srcSet={CarouselImage03x2}
                    media="(min-width: 767px)"
                  />
                  <source srcSet={CarouselImage03} media="(min-width: 370px)" />
                  <img
                    srcSet={CarouselImage03}
                    alt="Hepatofelin banner"
                    className="d-block w-100 rounded mb-3"
                  />
                </picture>
              </Link>

              <Carousel.Caption className="carousel-caption rounded-bottom p-2">
                <Link to="/en/hepatofelin" className="link-no-style">
                  Hepatofelin - Powerful liver protecion
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className="section-heading">Products</h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="flex-row justify-content-start  ">
          <CardDeck className="mb-3 d-flex card-layout">
            <span className="left-rectangle d-lg-none"></span>

            <Link to="/en/lutein-complex" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={LuteinComplex}
                  srcSet={`${LuteinComplexx2} 2x, ${LuteinComplexx3} 3x`}
                  alt="Lutein Complex"
                />
                <Card.Body>
                  <Card.Title>Lutein Complex Plus</Card.Title>
                  <Card.Text className="p-card-text">
                    Maximum eyesight protection
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/hepatofelin" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={Hepatofelin}
                  srcSet={`${Hepatofelinx2} 2x, ${Hepatofelinx3} 3x`}
                  alt="Hepatofelin"
                />
                <Card.Body>
                  <Card.Title>Hepatofelin</Card.Title>
                  <Card.Text className="p-card-text">
                    supports the liver
                    function
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/prostatizal-plus" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={ProstatizalP}
                  srcSet={`${ProstatizalPx2} 2x, ${ProstatizalPx3} 3x`}
                  alt="Prostatizal Plus"
                />
                <Card.Body>
                  <Card.Title>Prostatizal Plus</Card.Title>
                  <Card.Text className="p-card-text">
                    strengthens prostate health
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/psoriazal-plus" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={PsoriazalP}
                  srcSet={`${PsoriazalPx2} 2x, ${PsoriazalPx3} 3x`}
                  alt="Psoriazal Plus"
                />
                <Card.Body>
                  <Card.Title>Psoriazal Plus</Card.Title>
                  <Card.Text className="p-card-text">
                    supports skin health
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <span className="right-rectangle d-lg-none"></span>
          </CardDeck>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <h5 className="section-heading">Health topics</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Link to="/en/lutein-complex">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              eyes
            </Button>
          </Link>
          <Link to="/en/hepatofelin">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              liver
            </Button>
          </Link>
          <Link to="/en/prostatizal-plus">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              prostate
            </Button>
          </Link>
          <Link to="/en/psoriazal-plus">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              skin
            </Button>
          </Link>
          <Link to="/en/hepatofelin">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              gallbladder
            </Button>
          </Link>
          <Link to="/en/hepatofelin">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              liver enzymes
            </Button>
          </Link>

          <Link to="/en/lutein-complex">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              cataract
            </Button>
          </Link>
          <Link to="/en/psoriazal-plus">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              psoriasis
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="">
        <Col>
          <h5 className="section-heading">O'Yes Vita herbal products</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="flex-row justify-content-start  ">
          <CardDeck className="mb-3 d-flex card-layout">
            <span className="left-rectangle d-lg-none"></span>

            <Link to="/en/laksiprokt" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={LaksiproktImg} alt="Laxiproct" />
                <Card.Body>
                  <Card.Title className="h5--sm">Laxiproct</Card.Title>
                  <Card.Text className="p-card-text">for lazy bowel</Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/liporedukt" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={LiporeduktImg} alt="Liporeduct" />
                <Card.Body>
                  <Card.Title className="h5--sm">Liporeduct</Card.Title>
                  <Card.Text className="p-card-text">
                    for a balanced weight
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/menopauzal" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={MenopauzalImg} alt="Menopauzal" />
                <Card.Body>
                  <Card.Title className="h5--sm">Menopauzal</Card.Title>
                  <Card.Text className="p-card-text">for menopause</Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/miomal" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={MiomalImg} alt="Miomal" />
                <Card.Body>
                  <Card.Title className="h5--sm">Miomal</Card.Title>
                  <Card.Text className="p-card-text">
                    for the female reproductive system
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/veniprotekt" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img
                  variant="top"
                  src={VeniprotektImg}
                  alt="Veniprotect"
                />
                <Card.Body>
                  <Card.Title className="h5--sm">Veniprotect</Card.Title>
                  <Card.Text className="p-card-text">
                    for varicose veins
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/nevroherb" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={NevroherbImg} alt="Nevroherb" />
                <Card.Body>
                  <Card.Title className="h5--sm">Nevroherb</Card.Title>
                  <Card.Text className="p-card-text">
                    for the nervous system
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/pielonefral" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img
                  variant="top"
                  src={PielonefralImg}
                  alt="Pielonefral"
                />
                <Card.Body>
                  <Card.Title className="h5--sm">Pielonefral</Card.Title>
                  <Card.Text className="p-card-text">for kidneys</Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/prostatizal" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img
                  variant="top"
                  src={ProstatizalImg}
                  alt="Prostatizal"
                />
                <Card.Body>
                  <Card.Title className="h5--sm">Prostatizal</Card.Title>
                  <Card.Text className="p-card-text">
                    supports the prostate gland
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/en/psoriazal" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={PsoriazalImg} alt="Psoriazal" />
                <Card.Body>
                  <Card.Title className="h5--sm">Psoriazal</Card.Title>
                  <Card.Text className="p-card-text">
                    against psoriasis
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <span className="right-rectangle d-lg-none"></span>
          </CardDeck>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5 className="section-heading">Herbal aid</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Link to="/en/psoriazal">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              skin
            </Button>
          </Link>
          <Link to="/en/pielonefral">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              kidneys
            </Button>
          </Link>
          <Link to="/en/veniprotekt">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              veins
            </Button>
          </Link>
          <Link to="/en/nevroherb">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              nervous system
            </Button>
          </Link>
          <Link to="/en/menopauzal">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              menopause
            </Button>
          </Link>
          <Link to="/en/liporedukt">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              balanced weight
            </Button>
          </Link>
          <Link to="/en/laksiprokt">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              constipation
            </Button>
          </Link>
          <Link to="/en/prostatizal">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              prostate
            </Button>
          </Link>
          <Link to="/en/psoriazal">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              sarsaparilla
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5 className="section-heading">Helpful information</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-12 col-sm-6">
          <Link to="/en/technology" className="link-no-style">
            <Card className="d-flex flex-row info-button mb-4">
              <Card.Img
                className="rounded-left"
                src={TechImg}
                alt="Licaps&#8482; технология на производство"
              />
              <Card.Body>
                <Card.Text className="p-card-text">
                  Licaps&#8482; manufacturing technology
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col className="col-12 col-sm-6">
          <Link to="/en/about" className="link-no-style">
            <Card className="d-flex flex-row info-button mb-4">
              <Card.Img
                className="rounded-left"
                src={AboutImg}
                alt="About Vita Herb"
              />
              <Card.Body>
                <Card.Text className="p-card-text">About Vita Herb</Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPageEn
